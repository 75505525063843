<script>
import axios from "axios";
import Swal from "sweetalert2";
import ReOrderTasksModal from "@/views/pages/tasks/modals/ReOrderTasks.vue";
import TaskCreateModal from "@/views/pages/tasks/modals/TaskCreateModal.vue";
import TaskUpdateModal from "@/views/pages/tasks/modals/TaskUpdateModal.vue";
import TaskDeleteModal from "@/views/pages/tasks/modals/TaskDeleteModal.vue";
import TaskFilesCrudModal from "@/views/pages/tasks/modals/TaskFilesCrudModal.vue";
import store from "@/state/store";

export default {
  name: "TasksList",
  emits: ['onTaskCompleted'],
  data() {
    return {
      socket: null,

      searchQuery: '',
      status: '',
      manager: '',
      is_loading_tasks: true,
      todoList: [],
      managers_options: []
    }
  },

  components: {
    ReOrderTasksModal,
    TaskCreateModal,
    TaskUpdateModal,
    TaskDeleteModal,
    TaskFilesCrudModal
  },
  computed: {
    project() {
      return !this.is_loading_tasks ? this.todoList[0] ? this.todoList[0].project : null : null
    },
    getUser() {
      return store.state.user
    },
    isAdmin() {
      return this.getUser.role.toLowerCase() === 'admin'
    }
  },
  mounted() {
    this.socket = new WebSocket(`${process.env.VUE_APP_SOCKET_URL}/ws/tasks/`);
    this.socket.onmessage = () => {
      this.getProjectTasks()
    };
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  },
  methods: {
    async getProjectTasks(set_managers_options = false) {
      this.is_loading_tasks = true
      try {
        let response = await axios.get(`/task/list/${this.$route.params.project_id}/`, {
          params: {
            search: this.searchQuery.trim().length > 0 ? this.searchQuery.trim() : undefined,
            is_completed: this.status.trim().length > 0 ? this.status === 'completed' : undefined,
            manager: this.manager.toString().trim().length > 0 ? this.manager : undefined
          }
        })
        this.todoList = response.data.results
        if (set_managers_options) this.setManagersOptions()
        this.is_loading_tasks = false
      } catch {
        this.is_loading_tasks = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't get tasks",
        });
      }
    },
    setManagersOptions(list) {
      if (!list) list = this.todoList
      this.managers_options = [];
      let currentUser = null;

      list.forEach(task => {
        task.owners.forEach(owner => {
          if (owner.id === this.getUser.id) {
            currentUser = {
              value: owner.id,
              label: owner.username
            };
          } else if (!this.managers_options.map(manager => manager.value).includes(owner.id)) {
            this.managers_options.push({
              value: owner.id,
              label: owner.username
            });
          }
        });
      });

      if (currentUser) {
        this.managers_options.unshift(currentUser);
      }
    },
    async completeTask(task) {
      if (!task) return alert("Invalid Task")
      if (task.is_being_completed) return
      task.is_being_completed = true
      try {
        await axios.post(`/task/complete/${task.id}/`)
      } catch {
        task.is_being_completed = false
      }
      this.$emit('onTaskCompleted')
    },
  },
  watch: {
    "$route.params.project_id": {
      handler(value) {
        if (value) {
          this.todoList = []
          if (this.status !== '' || this.manager !== '') {
            this.status = ''
            this.manager = ''
          } else {
            this.getProjectTasks(true)
          }
        }
      },
      immediate: true
    },
    searchQuery() {
      this.getProjectTasks()
    },
    status() {
      this.getProjectTasks()
    },
    manager() {
      this.getProjectTasks()
    }
  }
}
</script>

<template>
  <ReOrderTasksModal
      @onTasksReOrdered="getProjectTasks()"
      ref="reOrderTasksModal"
      v-if="isAdmin"
  />

  <TaskCreateModal
      ref="taskCreateModal"
      v-if="isAdmin"
  />

  <TaskUpdateModal
      ref="taskUpdateModal"
      v-if="isAdmin"
  />

  <TaskDeleteModal
      ref="taskDeleteModal"
      v-if="isAdmin"
  />

  <TaskFilesCrudModal
      ref="taskFilesCrudModal"
  />

  <div class="w-100">
    <b-card no-body class="mb-3">
      <b-card-body>
        <div class="d-flex justify-content-between align-items-center gap-3 mb-3">
          <div class="d-flex align-items-center gap-3 w-75">
            <div>
              <select class="form-control" data-choices data-choices-search-false
                      name="choices-select-status" id="choices-select-status" v-model="status">
                <option value="">All Tasks</option>
                <option value="completed">Completed</option>
                <option value="pending">Pending</option>
              </select>
            </div>
            <div class="d-flex flex-row gap-3 w-100">
              <template v-for="(user, index) in managers_options" :key="`manager_filter_by_${user.value}`">
                <div
                    @mouseover="user.is_hovered = true"
                    @mouseleave="user.is_hovered = false"
                    @click="manager === user.value ? manager = '' : manager = user.value"
                    :class="{
                  'border-end pe-3' : index !== managers_options.length - 1,
                'border border-primary rounded': user.is_hovered || manager === user.value,
                    }"
                    v-b-tooltip.hover :title="`Click to filter by ${user.label}`"
                    class="d-flex gap-2 align-items-center p-1 cursor-pointer">
                  <div class="avatar-xs gap-3">
                    <div :class="{
                    'bg-primary text-white': manager === user.value,
                    'bg-soft-primary text-primary': manager !== user.value
                   }"
                         class="avatar-title text-uppercase rounded-circle flex-shrink-0 rounded cursor-pointer">
                      {{ getUser.id === user.value ? 'Me' : user.label[0] }}
                    </div>
                  </div>
                  <span>{{ getUser.id === user.value ? 'My tasks' : user.label }}</span>
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex gap-3" v-if="isAdmin">
            <b-button
                :disabled="todoList.length <= 1"
                @click="this.$refs.reOrderTasksModal.openModal(todoList)"
                variant="outline-success" class="createTask"><i
                class="mdi mdi-order-bool-descending-variant align-middle fs-5"></i> Reorder Tasks
            </b-button>
            <b-button
                @click="this.$refs.taskCreateModal.openModal()"
                variant="success" class="createTask"><i
                class="ri-add-fill align-bottom"></i> Add Tasks
            </b-button>
          </div>
        </div>
        <div class="search-box">
          <input type="text" id="searchTaskList" class="form-control search"
                 placeholder="Search task name" v-model="searchQuery">
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-card-body>
    </b-card>


    <div class="todo-content position-relative px-4 mx-n4" id="todo-content">
      <b-card no-body>
        <b-card-body>
          <div v-if="project" class="d-flex align-items-center justify-content-between gap-3 mb-3">
            <h5 class="mb-0">{{ project.name }}</h5>
            <div class="d-flex align-items-center gap-3">
              <BButton
                  v-for="order in project.orders" :key="`project_order_${order.id}`"
                  size="sm" class="btn-soft-secondary waves-effect waves-light">{{ order.order_number }}
              </BButton>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-middle position-relative table-nowrap mb-0">
              <thead class="table-active">
              <tr>
                <th scope="col">Task Name</th>
                <th scope="col">Task Description</th>
                <th scope="col">Attachments</th>
                <th scope="col">Managers</th>
                <th scope="col">Deadline</th>
                <th scope="col">Status</th>
                <th v-if="isAdmin" scope="col">Actions</th>
              </tr>
              </thead>
              <tbody v-if="!is_loading_tasks">
              <template v-if="todoList && todoList.length > 0">
                <template v-for="(task, index) of todoList" :key="`task_id_${task.id}`">
                  <tr class="align-middle draggable-row w-100">
                    <td>
                      <div class="form-check">
                        <template v-if="task.is_being_completed">
                          <i class="mdi mdi-loading mdi-spin"></i>
                        </template>
                        <template v-else>
                          <div class="d-flex align-items-center gap-3">
                            <span>{{ index + 1 }}. </span>
                            <div class="form-check form-check-success">
                              <input class="form-check-input" type="checkbox" :id="`check_task_${task.id}`"
                                     :checked="task.is_completed" @click="completeTask(task)">
                              <label
                                  :class="{
                            'text-decoration-line-through': task.is_completed
                              }"
                                  class="form-check-label ms-2" :for="`check_task_${task.id}`">
                                {{ task.title }}
                              </label>
                            </div>
                          </div>
                        </template>
                      </div>
                    </td>
                    <td> {{ task.description }}</td>
                    <td>
                      <div @click="this.$refs.taskFilesCrudModal.openModal(task)">
                        <i class="mdi mdi-eye text-primary me-2"></i>
                        <span class="cursor-pointer text-primary">Attachments ({{ task.files.length }})</span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <a v-for="user in task.owners" :key="`task_owner_${user.id}`"
                           class="avatar-group-item material-shadow-none">
                          <div class="avatar-xxs">
                            <div class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                              {{ user.username ? user.username[0] : '' }}
                            </div>
                          </div>
                        </a>
                      </div>
                    </td>
                    <td>
                      {{ task.due_date }}
                    </td>
                    <td>
                    <span :class="{
                      'badge bg-soft-danger text-danger': !task.is_completed,
                      'badge bg-soft-success text-success': task.is_completed
                    }">
                      {{ task.is_completed ? 'Completed' : 'Pending' }}
                    </span>
                    </td>
                    <td v-if="isAdmin">
                      <div class="hstack gap-2">
                        <b-button
                            @click="this.$refs.taskDeleteModal.openModal(task)"
                            variant="soft-danger" size="sm" class="remove-list"
                            data-remove-id={{task.id}}><i
                            class="ri-delete-bin-5-fill align-bottom"></i></b-button>
                        <b-button
                            @click="this.$refs.taskUpdateModal.openModal(task)"
                            variant="success" size="sm" class="edit-list"
                            data-edit-id={{task.id}}><i
                            class="ri-pencil-fill align-bottom"></i>
                        </b-button>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr class="align-middle">
                  <td colspan="7" class="pt-4 pb-3">
                    <div class="text-center">
                      <p class="my-0 text-muted">No tasks found</p>
                    </div>
                  </td>
                </tr>
              </template>
              </tbody>
              <tbody v-else>
              <tr class="align-middle">
                <td colspan="7" class="pt-4 pb-3">
                  <b-spinner class="mb-0"></b-spinner>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-card-body>
      </b-card>
    </div>

  </div>
</template>

<style scoped>

</style>