<script>
import {VueDraggableNext} from 'vue-draggable-next';
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ReOrderTasks",
  emits: ['onTasksReOrdered'],
  data() {
    return {
      todoList: [],
      showModal: false,
      is_saving: false
    }
  },
  components: {
    draggable: VueDraggableNext,
  },
  methods: {
    openModal(todoList) {
      if (!todoList) return alert("Invalid todo list")
      this.todoList = [...todoList]
      this.showModal = true
    },
    async onDragEnd() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        showCloseButton: true,
      });
      this.is_saving = true
      try {
        await axios.put(`/task/task/positions/update/${this.$route.params.project_id}/`, {
          positions: this.todoList.map(task => task.id)
        })
        this.is_saving = false
        this.$emit('onTasksReOrdered')
        this.showModal = false
      } catch {
        this.is_saving = false
        await Toast.fire({
          icon: "error",
          title: "Couldn't reorder tasks",
        });
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="ReOrder Modal" centered hide-footer>

    <p v-if="is_saving">
      <i class="mdi mdi-loading mdi-spin fs-5 text-success me-2 align-middle"></i>
      Saving...
    </p>
    <b-list-group>
      <draggable @end="onDragEnd"
                 :list="todoList" class="dragArea">
        <BListGroupItem v-for="task in todoList" :key="`task_${task.id}`" tag="label">
          <div class="form-check form-check-success">
            <input class="form-check-input" type="checkbox" :id="`check_task_order_${task.id}`" disabled
                   :checked="task.is_completed">
            <label class="form-check-label" :for="`check_task_order_${task.id}`">
              {{ task.title }}
            </label>
          </div>
        </BListGroupItem>
      </draggable>
    </b-list-group>
  </b-modal>
</template>

<style scoped>

</style>