<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "TaskCreateModal",
  emits: ['onTaskUpdated'],
  data() {
    return {
      showModal: false,
      is_saving_task: false,

      task: {
        user_ids: [],
        title: '',
        description: '',
        files: [],
        deadline: 'one_week'
      },

      select_users: {
        options: [],
        mode: 'tags',
        searchable: true,
        placeholder: 'Search for users...',
        clearOnSelect: false
      },
    }
  },
  components: {
    Multiselect
  },
  methods: {
    openModal(task) {
      if (!task) return alert("Invalid Task")
      this.task = {
        id: task.id,
        user_ids: (task.owners || []).map(user => user.id),
        title: task.title,
        description: task.description,
        deadline: moment(task.due_date).format('YYYY-MM-DD'),
      }
      this.select_users.options = task.owners.map(user => {
        return {
          value: user.id,
          label: user.username,
        }
      })
      this.showModal = true
    },
    async asyncFindUsers(query) {
      if (query.trim().length <= 0) return;
      try {
        let response = await axios.get("/user/", {
          params: {
            username: query.trim(),
          },
        });
        let newOptions = response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });

        this.select_users.options = [...this.select_users.options, ...newOptions];

        this.select_users.options = this.select_users.options.reduce((unique, o) => {
          if (!unique.find(obj => obj.value === o.value && obj.label === o.label)) {
            unique.push(o);
          }
          return unique;
        }, []);
      } catch {
        alert("Error")
      }
    },
    async updateTask() {
      this.is_saving_task = true
      try {

        await axios.put(`/task/update/${this.task.id}/`, {
          title: this.task.title,
          description: this.task.description,
          due_date: this.task.deadline,
          owners: this.task.user_ids,
        })

        this.showModal = false
        this.task = {
          user_ids: [],
          title: '',
          description: '',
          files: [],
        }
        this.$emit('onTaskUpdated')
        this.is_saving_task = false
      } catch {
        this.is_saving_task = false
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
}
</script>

<template>
  <b-modal v-model="showModal" title="Update task dialog" hide-footer centered size="lg">
    <form
        @submit.prevent="updateTask"
        class="d-flex flex-column gap-4">
      <div>
        <label class="form-label">Assigned managers</label>
        <Multiselect
            v-model="task.user_ids"
            v-bind="select_users"
            @search-change="asyncFindUsers"
            class="ps-1" :clearOnSelect="false"
            :disabled="is_saving_task"
        />
      </div>

      <div>
        <label class="form-label">Task title</label>
        <input v-model="task.title" class="form-control"
               :disabled="is_saving_task" placeholder="Enter task title...">
      </div>

      <div>
        <label class="form-label">Task description</label>
        <textarea v-model="task.description" class="form-control" rows="5"
                  :disabled="is_saving_task"
                  placeholder="Enter task description..."></textarea>
      </div>


      <div>
        <label class="form-label">Deadline</label>
        <input v-model="task.deadline" class="form-control" type="date"
               :disabled="is_saving_task" placeholder="">
      </div>

      <div>
        <b-button variant="success" size="lg"
                  :disabled="is_saving_task"
                  class="w-100" :type="is_saving_task? 'button' : 'submit'">
          {{ is_saving_task ? 'Saving' : 'Save' }}
          <b-spinner v-if="is_saving_task" class="ms-1" small label="Spinning..."></b-spinner>
        </b-button>
      </div>

    </form>
  </b-modal>
</template>

<style scoped>

</style>