<script>
import axios from "axios";
import Swal from "sweetalert2";
import store from "@/state/store";

export default {
  name: "TaskFilesCrudModal",
  emits: ['onFileDeleted', 'onFileCreated'],
  data() {
    return {
      showModal: false,
      task: {},

      file_extensions: {
        'pdf': {
          icon: 'bx bxs-file-pdf',
          color: 'danger'
        },
        'doc': {
          icon: 'bx bxs-file-doc',
          color: 'secondary'
        },
        'docx': {
          icon: 'bx bxs-file-doc',
          color: 'secondary'
        },
        'xls': {
          icon: 'mdi mdi-microsoft-excel',
          color: 'success'
        },
        'xlsx': {
          icon: 'mdi mdi-microsoft-excel',
          color: 'success'
        },
        'ppt': {
          icon: 'mdi mdi-file-powerpoint',
          color: 'warning'
        },
        'pptx': {
          icon: 'mdi mdi-file-powerpoint',
          color: 'warning'
        },
        'jpg': {
          icon: 'bx bxs-file-image',
          color: 'info'
        },
        'jpeg': {
          icon: 'bx bxs-file-image',
          color: 'info'
        },
        'png': {
          icon: 'bx bxs-file-image',
          color: 'info'
        },
        'gif': {
          icon: 'bx bxs-file-gif',
          color: 'light'
        },
        'txt': {
          icon: 'bx bxs-file-txt',
          color: 'dark'
        },
        'zip': {
          icon: 'bx bxs-file-archive',
          color: 'dark'
        },
        'rar': {
          icon: 'bx bxs-file-archive',
          color: 'dark'
        },
        '7z': {
          icon: 'bx bxs-file-archive',
          color: 'dark'
        },
      },

      files_count: 0,

      is_submitting_files: false
    }
  },
  computed: {
    isAdmin() {
      return store.state.user.role.toLowerCase() === 'admin'
    }
  },
  methods: {
    openModal(task) {
      if (!task) return alert("Invalid Task")
      this.task = task
      this.showModal = true
    },
    async downloadFile(path) {
      if (!path) return;
      let url = process.env.VUE_APP_ORDER_URL + path;
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
            this.is_downloading_file = false
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    async deleteFile(file) {
      if (!file) return alert("Invalid File")
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      })
      try {
        await axios.delete(`/task/remove_file/${file.id}/`)
        this.task.files = this.task.files.filter(item => item.id !== file.id)
        await this.$emit('onFileDeleted')
        await Toast.fire({
          icon: 'success',
          title: 'File deleted successfully',
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'Couldn\'t delete file',
        })
      }
    },
    async deleteFileConfirmation(file) {
      if (!file) return alert("Invalid File")
      await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteFile(file)
        }
      })
    },

    async submitNewFiles() {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      })
      try {
        this.is_submitting_files = true
        let formData = new FormData()
        for (let i = 0; i < this.$refs.file_input.files.length; i++) {
          formData.append('files', this.$refs.file_input.files[i])
        }
        await axios.post(`/task/create_file/${this.task.id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.is_submitting_files = false
        await this.$emit('onFileCreated')
      } catch {
        this.is_submitting_files = false
        await Toast.fire({
          icon: 'error',
          title: 'Couldn\'t submit files',
        })
      }
    },

    onFileInputChange() {
      this.files_count = this.$refs.file_input.files.length
    },
  }
}
</script>

<template>

  <b-modal v-model="showModal" hide-footer class="v-modal-custom" body-class="p-0" centered
           hide-header-close>
    <div class="p-4 pt-2">
      <div class="d-flex justify-content-between mb-4">
        <h4 class="mb-0">Attachments</h4>
        <div class="text-end">
          <button type="button" class="btn-close text-end" @click="showModal = false"
                  data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      </div>
      <div class="mt-2">

        <b-list-group class="mb-4" flush v-if="(task.files || []).length > 0">
          <TransitionGroup name="list" tag="BListGroupItem">
            <template v-for="file in (task.files || [])" :key="`task_file_${file.id}`">
              <BListGroupItem
                  class="d-flex justify-content-between align-items-center gap-3 ps-2 border-top-0 border-start-0 border-end-0"
                  :class="{
              'border-bottom': (task.files || []).length === 1
            }">
                <div class="d-flex gap-2 align-items-center text-truncate">
                  <div>
                    <div class="avatar-xs" v-b-tooltip.hover :title="file.file.split('.').pop()">
                      <div class="avatar-title rounded"
                           :class="[`bg-soft-${file_extensions[file.file.split('.').pop()].color}`, `text-${file_extensions[file.file.split('.').pop()].color}`]"
                      >
                        <i :class="[file_extensions[file.file.split('.').pop()].icon]" class="fs-4"></i>
                      </div>
                    </div>
                  </div>
                  <div class="text-truncate">
                    <span class="mb-0">{{ file.file.split('/').pop() }}</span>
                  </div>
                </div>
                <div class="d-flex gap-2">
                  <b-button v-if="isAdmin" @click="deleteFileConfirmation(file)"
                            variant="soft-danger" size="sm">
                    <i class="ri-delete-bin-5-fill align-bottom"></i>
                  </b-button>
                  <b-button @click="downloadFile(file.file)"
                            variant="soft-primary" size="sm">
                    <i class="mdi mdi-download align-bottom"></i>
                  </b-button>
                </div>
              </BListGroupItem>
            </template>
          </TransitionGroup>
        </b-list-group>

        <div v-else class="mb-4">
          <span class="text-muted">No attachments found</span>
        </div>


        <form v-if="isAdmin" @submit.prevent="submitNewFiles()">
          <b-accordion>
            <BAccordionItem title="Attach new files ?">
              <label class="form-label text-dark">Select Files</label>
              <p class="text-muted">You can select multiple files at a time</p>
              <input ref="file_input" @change="onFileInputChange"
                     class="form-control" type="file" multiple/>

              <b-button v-if="files_count > 0" :disabled="is_submitting_files"
                        :type="is_submitting_files ? 'button' : 'submit'"
                        variant="success" class="w-100 mt-3">
                <span class="align-middle w-100">Submit Files</span>
              </b-button>
            </BAccordionItem>
          </b-accordion>

        </form>

      </div>
    </div>
    <div class="modal-footer bg-light p-3 justify-content-center">
      <p class="mb-0 text-muted text-center">You're seeing the attachments of
        <span v-b-tooltip title="Task title"
              class="link-secondary fw-semibold">{{ task.title }}
        </span>
      </p>
    </div>
  </b-modal>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>