<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "TaskCreateModal",
  emits: ['onTaskCreated'],
  data() {
    return {
      showModal: false,
      is_creating_task: false,

      task: {
        user_ids: [],
        title: '',
        description: '',
        files: [],
        deadline: 'one_week'
      },

      select_users: {
        options: [],
        mode: 'tags',
        searchable: true,
        placeholder: 'Search for users...',
        clearOnSelect: false
      },
      select_priority: {
        options: [
          {
            value: 3,
            label: 'Low'
          },
          {
            value: 2,
            label: 'Medium'
          },
          {
            value: 1,
            label: 'High'
          }
        ],
        placeholder: 'Select priority'
      }
    }
  },
  components: {
    Multiselect
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    async loadSomeUsers() {
      try {
        let response = await axios.get("/user/", {
          params: {
            company: 'interrail',
          },
        });
        this.select_users.options = response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });
      } catch {
        alert("Error")
      }
    },
    async asyncFindUsers(query) {
      if (query.trim().length <= 0) return;
      try {
        let response = await axios.get("/user/", {
          params: {
            username: query.trim(),
          },
        });
        let newOptions = response.data.results.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });

        this.select_users.options = [...this.select_users.options, ...newOptions];

        this.select_users.options = this.select_users.options.reduce((unique, o) => {
          if (!unique.find(obj => obj.value === o.value && obj.label === o.label)) {
            unique.push(o);
          }
          return unique;
        }, []);
      } catch {
        alert("Error")
      }
    },
    async createTask() {
      this.is_creating_task = true
      try {
        let formData = new FormData()

        let files = document.querySelector('#task_files').files

        switch (this.task.deadline) {
          case 'one_week':
            formData.append('due_date', moment().add(7, 'days').format('YYYY-MM-DD'))
            break
          case 'two_weeks':
            formData.append('due_date', moment().add(14, 'days').format('YYYY-MM-DD'))
            break
          case 'one_month':
            formData.append('due_date', moment().add(30, 'days').format('YYYY-MM-DD'))
            break
          default:
            formData.append('due_date', moment(this.task.deadline).format('YYYY-MM-DD'))
            break
        }

        formData.append('title', this.task.title)
        formData.append('description', this.task.description)

        this.task.user_ids.forEach(item => {
          formData.append('owners', item)
        })

        for (let i = 0; i < files.length; i++) {
          formData.append('files', files[i])
        }

        await axios.post(`/task/create/${this.$route.params.project_id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.showModal = false
        this.task = {
          user_ids: [],
          title: '',
          description: '',
          files: [],
        }
        document.querySelector('#task_files').value = ''
        this.$emit('onTaskCreated')
        this.is_creating_task = false
      } catch {
        this.is_creating_task = false
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
  mounted() {
    this.loadSomeUsers()
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Assign a new task" hide-footer centered size="lg">
    <form
        @submit.prevent="createTask"
        class="d-flex flex-column gap-4">
      <div>
        <label class="form-label">Assign to</label>
        <Multiselect
            v-model="task.user_ids"
            v-bind="select_users"
            @search-change="asyncFindUsers"
            class="ps-1" :clearOnSelect="false"
            :disabled="is_creating_task"
        />
      </div>

      <div>
        <label class="form-label">Task title</label>
        <input v-model="task.title" class="form-control"
               :disabled="is_creating_task" placeholder="Enter task title...">
      </div>

      <div>
        <label class="form-label">Task description</label>
        <textarea v-model="task.description" class="form-control" rows="5"
                  :disabled="is_creating_task"
                  placeholder="Enter task description..."></textarea>
      </div>


      <div>
        <label class="form-label">Deadline</label>
        <b-row class="justify-content-between align-items-end" gutter-y="3">
          <b-col xl="5">
            <input v-model="task.deadline" class="form-control" type="date"
                   :disabled="is_creating_task" placeholder="">
          </b-col>

          <b-col xl="7">
            <BButton-group role="group" aria-label="Basic radio toggle button group" class="mb-0">
              <input v-model="task.deadline" type="radio" value="one_week" class="btn-check" name="btnradio"
                     id="btnradio1" autocomplete="off"
                     checked="">
              <label class="btn btn-outline-success mb-0" for="btnradio1">1 Week</label>

              <input v-model="task.deadline" type="radio" value="two_weeks" class="btn-check" name="btnradio"
                     id="btnradio2" autocomplete="off">
              <label class="btn btn-outline-success mb-0" for="btnradio2">2 Weeks</label>

              <input v-model="task.deadline" type="radio" value="one_month" class="btn-check" name="btnradio"
                     id="btnradio3" autocomplete="off">
              <label class="btn btn-outline-success mb-0" for="btnradio3">A Month</label>
            </BButton-group>
          </b-col>
        </b-row>
      </div>

      <div>
        <label class="form-label">Files</label>
        <input class="form-control"
               :disabled="is_creating_task"
               type="file" multiple id="task_files"
               placeholder="Enter task title...">
      </div>

      <div>
        <b-button variant="success" size="lg"
                  :disabled="is_creating_task"
                  class="w-100" :type="is_creating_task? 'button' : 'submit'">
          {{ is_creating_task ? 'Assigning' : 'Assign' }}
          <b-spinner v-if="is_creating_task" class="ms-1" small label="Spinning..."></b-spinner>
        </b-button>
      </div>

    </form>
  </b-modal>
</template>

<style scoped>

</style>